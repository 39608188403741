<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { keyDown } from "./keyDown";
  import Tooltip from "./Tooltip.svelte";

  export let isOpen = false;
  export let title: string | undefined = undefined;
  export let boldTitle: boolean = false;
  export let selectNone = false;
  export let allowClosing = true;
  export let noPadding: boolean = false;
  export let hideIcon = false;

  let isPopupVisible = false;

  const dispatch = createEventDispatcher();
  const html = document.querySelector("html")!;
  const toolbar = document.getElementsByClassName("tb-container")![0] as Element & { style: { zIndex: string } };

  // overflowY = '' will bring back the scroll after closing the modal.
  const originalOverflow = html.style.overflowY;
  const originalPosition = html.style.position;
  let originalToolbarZIndex = "201";
  let popupDiv: HTMLDivElement;
  let closing: boolean = false;
  let closeDetail: any = undefined;

  $: if (isPopupVisible) {
    if (toolbar) {
      originalToolbarZIndex = toolbar.style.zIndex;
      toolbar.style.zIndex = "0";
    }
    html.style.position = "fixed";
    html.style.overflowY = "scroll";
  }

  $: {
    if (isOpen && !isPopupVisible) {
      isPopupVisible = true;
    } else if (!closing && !isOpen && isPopupVisible) {
      closeModal();
    }
  }

  async function doCloseModal() {
    dispatch("close", closeDetail);
    isPopupVisible = false;
    isOpen = false;
    closeDetail = undefined;
  }

  function animationEnd() {
    popupDiv?.removeEventListener("animationend", animationEnd);
    doCloseModal();
    // restore the overlay value when we're being unmounted
    if (toolbar) {
      toolbar.style.zIndex = originalToolbarZIndex;
    }
    // Resetting the html's properties back to enable scrolling
    html.style.position = originalPosition;
    html.style.overflowY = originalOverflow;
    closing = false;
  }

  function closeModal(options?: { force: boolean }) {
    if (!allowClosing && !options?.force) {
      return;
    }

    closing = true;
    popupDiv.addEventListener("animationend", animationEnd);
  }

  function forceCloseModal(args: any) {
    closeDetail = args?.detail;
    return closeModal({ force: true });
  }

  function clickedOutside(e: MouseEvent) {
    if (e.target === popupDiv) {
      e.stopPropagation();
      closeModal();
    }
  }
</script>

{#if isPopupVisible}
  <div
    bind:this={popupDiv}
    use:keyDown={[{ key: "Escape", handler: () => closeModal() }]}
    class="fixed inset-0 flex items-center justify-center popup backdrop-blur-sm sm:mt-0"
    class:select-none={selectNone}
    class:closing
    on:click={clickedOutside}
    style="z-index:99999;">
    <div
      class="absolute z-30 max-w-full max-h-screen mt-0 rounded-lg shadow-md popup-container"
      class:p-2={!noPadding}
      class:p-5={!noPadding}>
      <div
        class="sticky z-10 flex items-center justify-between py-0 bg-white sm:static sm:bg-transparent sm:shadow-none sm:contents"
        class:pb-4={!noPadding}
        class:-mx-2={!noPadding}>
        {#if title}
          <div class="flex items-center mb-3 text-16">
            {#if !hideIcon}
              <slot name="icon">
                <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.3708 13.3995l4.0597 2.4554c.7435.45 1.6533-.2152 1.4576-1.0565l-1.0761-4.6173
                3.5902-3.11083c.6554-.56738.3033-1.64345-.5576-1.71193l-4.7249-.40108L9.27079.594284c-.3326-.792379-1.46737-.792379-1.79997
                0L5.62193 4.94748l-4.724921.40108c-.8608556.06847-1.213024 1.14454-.5576 1.71193L3.92957 10.1713 2.8535
                14.7886c-.19565.8413.71412 1.5065 1.45759 1.0565l4.05971-2.4456z"
                    fill="#CBCBCF" />
                </svg>
              </slot>
            {/if}
            <span class:ml-2={!hideIcon} class:font-bold={boldTitle}>
              {title}
            </span>
          </div>
        {/if}
        <slot name="header" />
        {#if allowClosing}
          <button
            class="text-lg z-[9999] absolute top-0 p-2 bg-transparent rounded text-brand-gray sm:text-white right-2 sm:-right-11 material-icons clickable hover:bg-gray-50 hover:bg-opacity-40 focus:outline-none"
            on:click|stopPropagation={() => closeModal()}>
            close
            <Tooltip position="top">Close</Tooltip>
          </button>
        {/if}
      </div>
      <div class="flex flex-wrap items-center justify-start w-full overflow-y-clip sm:overflow-y-visible">
        <slot {forceCloseModal}>
          <h2>NO CONTENT</h2>
        </slot>
      </div>
      <div class="w-full">
        <slot name="footer" />
      </div>
    </div>
  </div>
{/if}

<style>
  :global(html) {
    width: 100%;
  }

  .popup {
    --animation-duration: 500ms;

    animation: fade-in var(--animation-duration) forwards;
    background-color: rgb(0 0 0 / 0.5);
    transition:
      background-color,
      backdrop-filter var(--animation-duration) ease-in-out;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .popup-container {
    width: 95%;
    animation: slide-in ease-in-out 300ms forwards;
    background-color: var(--popup-bg-color, #fff);
  }

  @media (orientation: landscape) {
    .popup-container {
      margin-top: 4rem;
    }
    @media (min-width: 980px) {
      .popup-container {
        height: var(--popup-height, unset) !important;
      }
    }
  }

  @media (min-width: 640px) {
    .popup-container {
      width: var(--popup-width, 840px);
    }
  }

  @keyframes slide-in {
    from {
      transform: translateY(10%);
    }
    to {
      transform: translateY(0);
    }
  }

  .popup.closing {
    animation: fade-out var(--animation-duration) forwards;
    background-color: transparent;
    backdrop-filter: blur(0);
  }

  .popup.closing .popup-container {
    animation:
      scale-out var(--animation-duration) forwards,
      fade-out var(--animation-duration) forwards;
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes scale-out {
    to {
      transform: scale(0.8);
    }
  }
</style>
